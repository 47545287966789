<template>
  <div class="">
    <div class="chart-container">
      <div class="chart-header">
        <div class="flex items-center" style="gap: 8px">
          <p>整體績效分析</p>
          <TipInfo :size="16">
            此績效分析是以個別服務人員的數值與系統全部美業組織人員數值比較，代表該人員在美業產業的表現
          </TipInfo>
        </div>
        <div class="divider" />
      </div>

      <section class="flex items-center justify-between">
        <div ref="chartRef" class="chart flex-shrink-0" />
        <div class="description-table">
          <div
            v-for="(row, rowIdx) in tableData"
            :key="rowIdx"
            class="table-row"
            :class="{
              'column-header': rowIdx === 0,
            }"
          >
            <p
              v-for="(cell, cellIdx) in row"
              :key="cellIdx"
              class="table-cell"
              :class="{
                'highlight-cell': rowIdx !==0 && cellIdx < 2,
                'bold-value': cell.bold,
              }"
            >
              {{ cell.label }}
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, watch } from 'vue'
import TipInfo from '@/components/TipInfo.vue'
import { useChart } from '@/use/dashboard'
import { get } from 'lodash'

export default defineComponent({
  name: 'BeautyAIAppointmentUnit360Analysis',
  components: { TipInfo },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const toDisplayStartValue = (v) => {
      if (v !== 0 && !v) return 0
      return v.toFixed(1)
    }
    const displayStartValueData = computed(() => {
      return [
        toDisplayStartValue(get(props.data, 'contributionStar')),
        toDisplayStartValue(get(props.data, 'orderTakingStar')),
        toDisplayStartValue(get(props.data, 'serviceEfficiencyStar')),
        toDisplayStartValue(get(props.data, 'customerStandardStar')),
        toDisplayStartValue(get(props.data, 'serviceTimeStar')),
      ]
    })

    const { chartRef, updateChart } = useChart({
      series: [
        {
          name: '分數',
          data: displayStartValueData.value,
        }],
      chart: {
        width: 420,
        height: 320,
        type: 'radar',
      },
      stroke: {
        width: 0,
      },
      yaxis: {
        stepSize: 1,
      },
      xaxis: {
        categories: ['業績貢獻', '接單能力', '服務效率', '客戶水準', '服務時間'],
      },
    })

    const tableData = computed(() => {
      return [
        [
          { label: '分析項目' },
          { label: '分數' },
          { label: '說明' },
        ],
        [
          { label: '業績貢獻' },
          { label: toDisplayStartValue(get(props.data, 'contributionStar')) },
          { label: '透過施作服務、銷售商品所獲得的業績' },
        ],
        [
          { label: '接單能力' },
          { label: toDisplayStartValue(get(props.data, 'orderTakingStar')) },
          { label: '被指定為服務人員的預約數量' },
        ],
        [
          { label: '服務效率' },
          { label: toDisplayStartValue(get(props.data, 'serviceEfficiencyStar')) },
          { label: '為顧客提供服務時的工作效率' },
        ],
        [
          { label: '客戶水準' },
          { label: toDisplayStartValue(get(props.data, 'customerStandardStar')) },
          { label: '客單價' },
        ],
        [
          { label: '服務時間' },
          { label: toDisplayStartValue(get(props.data, 'serviceTimeStar')) },
          { label: '平均服務間長短' },
        ],
        [
          { label: '整體績效' },
          { label: toDisplayStartValue(get(props.data, 'totalStar')), bold: true },
          { label: '前五項分數加總' },
        ],

      ]
    })

    watch(displayStartValueData, () => {
      updateChart({
        series: [
          {
            name: '分數',
            data: displayStartValueData.value,
          }],
      })
    })

    return { chartRef, tableData }
  },
})
</script>

<style lang="postcss" scoped>
.chart-container {
  @apply bg-white rounded-[8px] p-[20px];
}
.divider {
  @apply w-full h-[2px] bg-[#ECECEC] mt-[16px] mb-[20px];
}
.description-table {
  @apply border border-gray-40 rounded-[8px] overflow-hidden;
}
.table-row {
  @apply grid grid-cols-3 text-center;
}
.table-cell {
  @apply py-[12.5px] px-[12px];
  @apply border-b border-b-gray-40;
}

.column-header {
  /* @apply bg-[#87a8f9]; */
  @apply bg-primary-100;
}

.highlight-cell {
  /* @apply bg-[#f1f6ff]; */
  @apply bg-primary-30;
}
.bold-value {
  @apply text-primary-100 font-bold;
}
</style>
