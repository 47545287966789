<template>
  <div v-loading="loading">
    <div class="chart-container">
      <div class="chart-header">
        <p class="text-lg font-medium">年度服務概覽</p>
        <p class="text-sub text-gray-60">數據截止時間：{{ formatDate(chartData.lastUpdatedAt) }}</p>
      </div>
      <div class="divider" />
      <div ref="chartRef" class="chart" />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onBeforeMount, reactive } from 'vue'
import { dateRangeConfig } from '@/config/dashboard'
import store from '@/store'
import { useChart } from '@/use/dashboard'
import { GetBeautyAIAptUnitAnalysisYearlyChart } from '@/api/beautyAI'
import { useRoute } from 'vue-router/composables'
import { formatDate } from '@/utils/date'
import { map, reduce } from 'lodash'

export default defineComponent({
  name: 'BeautyAIAppointmentUnitAnalysisDetailAnnualChart',
  // components: { ChartTitle },
  props: {},
  setup (props, { emit }) {
    const route = useRoute()
    const loading = ref(false)
    const { chartRef, updateChart } = useChart({
      series: [
        {
          name: '服務人數',
          type: 'line',
          data: [],
        },
        {
          name: '主服務',
          type: 'bar',
          data: [],
        }, {
          name: '附加服務',
          type: 'bar',
          data: [],
        },
      ],
      chart: {
        height: 350,
        // stackOnlyBar: true,
        stacked: true,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [0, 1, 2],
      },
      legend: {
        position: 'top',
        showForSingleSeries: true,
        markers: {
          width: 12,
          height: 12,
          radius: 0,
          strokeWidth: 0,
        },
      },
      labels: [],
      yaxis: [{
        title: {
          text: '次數',
        },

      }],
    })
    const shopId = computed(() => store.getters.shop)
    const apyUnitId = computed(() => route.params.id)

    const chartData = reactive({
      lastUpdatedAt: null,
      serviceUserCount: [],
      mainService: [],
      attachService: [],
      dateLabels: [],
    })

    const refresh = async () => {
      if (!shopId.value) return
      loading.value = true
      const [res, err] = await GetBeautyAIAptUnitAnalysisYearlyChart({
        shopId: shopId.value,
        id: apyUnitId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      const dataHolder = res.result.sort((a, b) => new Date(a.month) - new Date(b.month))

      chartData.lastUpdatedAt = res.lastUpdatedAt
      chartData.dateLabels = map(dataHolder, (item) => formatDate(item.month, 'YYYY/MM/DD'))
      chartData.serviceUserCount = map(dataHolder, 'sumTotalUsers')
      chartData.mainService = map(dataHolder, 'sumTotalReservations')
      chartData.attachService = map(dataHolder, 'sumTotalServiceAttachCount')

      const seriesData = []

      // 如果陣列的值加總不為 0 才加入 seriesData
      if (reduce(chartData.serviceUserCount, (sum, n) => sum + n, 0) !== 0) {
        seriesData.push({
          name: '服務人數',
          data: chartData.serviceUserCount,
        })
      }

      if (reduce(chartData.mainService, (sum, n) => sum + n, 0) !== 0) {
        seriesData.push({
          name: '主服務',
          data: chartData.mainService,
        })
      }

      if (reduce(chartData.attachService, (sum, n) => sum + n, 0) !== 0) {
        seriesData.push({
          name: '附加服務',
          data: chartData.attachService,
        })
      }
      updateChart({
        series: seriesData,
        labels: chartData.dateLabels,
      })
    }

    onBeforeMount(async () => {
      refresh()
    })

    return {
      chartRef,
      dateRangeConfig,
      loading,
      chartData,
      formatDate,
    }
  },
})
</script>

<style lang="postcss" scoped>
.member-tag-statistics {
  @apply flex flex-col gap-5;
}

.chart-container {
  @apply bg-white p-[20px] pb-0 rounded-[8px];
}

.chart-header {
  @apply flex items-center justify-between text-gray-80;
}

.divider {
  @apply w-full h-[2px] bg-[#ECECEC] mt-[16px] mb-[20px];
}
</style>
